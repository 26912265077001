import { Paper, type SxProps, type Theme } from "@mui/material";
import clsx from "clsx";
import { ReactNode } from "react";

import styles from "./RegularContainer.module.scss";

interface IProps {
    children: ReactNode;
    containerClasses?: string;
    hasActionsBtns?: boolean;
    headingTitle?: string | null;
    actionButtons?: ReactNode;
    childrenClass?: string;
    sx?: SxProps<Theme>;
}

const RegularContainer = ({
    children,
    containerClasses,
    hasActionsBtns,
    headingTitle = "",
    actionButtons,
    childrenClass,
    sx,
}: IProps) => {
    return (
        <Paper sx={sx} className={`${styles.wrapper} ${containerClasses}`}>
            <div className={clsx(styles.headingBox, { [styles.headingMB]: headingTitle })}>
                {!!headingTitle && <h2 className={styles.headingTitle}>{headingTitle}</h2>}

                {hasActionsBtns && <div className={styles.actionWrapper}>{actionButtons}</div>}
            </div>
            <div className={childrenClass}>{children}</div>
        </Paper>
    );
};
export default RegularContainer;
