import categoryImg01 from "../../../assets/images/category01.png";
import categoryImg02 from "../../../assets/images/category02.png";
import categoryImg03 from "../../../assets/images/category03.png";

export const CATEGORY_ASSETS = [
    { img: categoryImg01, color: "#A47EFB" },
    { img: categoryImg02, color: "#F263A6" },
    { img: categoryImg03, color: "#5DCFC3" },
];

export const PROJECT_DESC_LIMIT = 100;
