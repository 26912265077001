import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import BackPage from "../../components/button/BackPage";
import RegularContainer from "../../components/container/RegularContainer";
import InnerTabs from "../../components/tab/InnerTabs";
import { ILinkTabProps } from "../../types/interfaces";
import { isProduction } from "../../utils";

const prodAccountRoutes = [
    { labelKey: "ANALYSIS.Data_OpenAI_Analysis", href: "usage" },
    { labelKey: "APIKEYS.Key", href: "api-keys" },
];

const accountRoutes: ILinkTabProps[] = [{ labelKey: "ACCOUNT.Current_Plan", href: "current-plan" }];
const currentAccountRoutes = isProduction ? prodAccountRoutes : [...accountRoutes, ...prodAccountRoutes];

const AccountTabs = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const location = useLocation();

    const [currentTab, setCurrentTab] = useState(0);

    const handleChange = (newValue: number) => {
        setCurrentTab(newValue);
    };

    const pathnameSplit = location?.pathname?.split("/");
    const currentRoute = pathnameSplit[pathnameSplit?.length - 1] || "";

    const findIdx = currentAccountRoutes.findIndex((r) => {
        return r.href === currentRoute;
    });

    useEffect(() => {
        if (findIdx !== -1) {
            setCurrentTab(findIdx);
            return;
        }
        setCurrentTab(0);
    }, [findIdx, currentRoute]);

    return (
        <>
            <RegularContainer headingTitle={t("ACCOUNT.My_Account")} sx={{ pb: 0 }}>
                <InnerTabs
                    currentTab={currentTab}
                    handleChange={handleChange}
                    tabRoutes={currentAccountRoutes}
                    tabsOnClick={(tabRoute) => {
                        navigate(`/account/billing/${tabRoute.href}`);
                    }}
                />
            </RegularContainer>
            <BackPage />
        </>
    );
};

export default AccountTabs;
