import { useTranslation } from "react-i18next";
import { object, string } from "yup";

export const ItemInputSchema = (isCreate: boolean, isOrder: boolean) => {
    const { t } = useTranslation();

    const schema = {
        name: string().required(`${t("COMMON.Required_Input")}`),
        category: string().required(`${t("COMMON.Required_Input")}`),
        ...(isOrder && { base_price: string().required(`${t("COMMON.Required_Input")}`) }),
    };

    return object().shape(schema);
};
