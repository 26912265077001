import { useReducer } from "react";

import { IFilter, TFilterActionType } from "../types/interfaces";

const reduceHandler = (state: IFilter, action: TFilterActionType): IFilter => {
    switch (action.type) {
        case "SET_FILTER_ITEM_NAME":
            return { ...state, itemName: action.payload };
        case "SET_FILTER_LANGUAGE":
            return { ...state, language: action.payload };
        case "SET_FILTER_CATEGORY_TYPE":
            return { ...state, categoryType: action.payload };
        case "SET_FILTER_PRICE_ORDER":
            return { ...state, priceOrder: action.payload };
        case "SET_FILTER_PRICE_RANGE":
            return { ...state, priceRange: action.payload };
        case "DELETE_FILTER":
            return { ...state, [action.payload]: "" };
        case "RESET_FILTER":
            return initFilterState;
        default:
            return initFilterState;
    }
};

const initFilterState: IFilter = {
    itemName: "",
    language: "繁體中文",
    categoryType: "",
    priceOrder: "",
    priceRange: { min: null, max: null },
};
const useFilterReducer = () => {
    const [filterState, dispatchFilterState] = useReducer(reduceHandler, initFilterState);

    return { filterState, dispatchFilterState };
};

export default useFilterReducer;
