import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { triggerPostMoveFlash } from "@atlaskit/pragmatic-drag-and-drop-flourish/trigger-post-move-flash";
import { extractClosestEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { reorderWithEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/util/reorder-with-edge";
import { useEffect, useState } from "react";
import { flushSync } from "react-dom";

interface IProps<T> {
    dndItems: T[];
    el: HTMLElement | null;
}
const useDragMonitor = <T extends { id: string }>({ el, dndItems }: IProps<T>) => {
    const [items, setItems] = useState<typeof dndItems>(dndItems);
    useEffect(() => {
        if (!el) return;
        // 協助開發用的 throw Error
        // if (!el) throw new Error("ref not set correctly");
        return monitorForElements({
            onDrop: ({ location, source }) => {
                const target = location.current.dropTargets[0];
                if (!target) {
                    return;
                }

                const sourceData = source.data;
                const targetData = target.data;

                if (!sourceData || !targetData) {
                    return;
                }

                const indexOfSource = items.findIndex((category) => category.id === sourceData.id);
                const indexOfTarget = items.findIndex((category) => category.id === targetData.id);

                if (indexOfTarget < 0 || indexOfSource < 0) {
                    return;
                }

                const closestEdgeOfTarget = extractClosestEdge(targetData);

                flushSync(() => {
                    setItems(
                        reorderWithEdge({
                            list: items,
                            startIndex: indexOfSource,
                            indexOfTarget,
                            closestEdgeOfTarget,
                            axis: "vertical",
                        })
                    );
                });
                const element = document.querySelector(`[data-task-id="${sourceData.taskId}"]`);
                if (element instanceof HTMLElement) {
                    triggerPostMoveFlash(element);
                }
            },
        });
    }, [items, el]);
    return { items };
};
export default useDragMonitor;
