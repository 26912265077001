import { IUserPermission } from "../types";

const TEMP_ADMIN: IUserPermission[] = [
    {
        email: "mulit.amaze@gmail.com",
        role: "admin",
    },
    {
        email: "ashiyang@barkingdog.ai",
        role: "admin",
    },
    {
        email: "chiumomo@barkingdog.ai",
        role: "admin",
    },
    {
        email: "ryan@barkingdog.ai",
        role: "admin",
    },
    {
        email: "clairema@barkingdog.ai",
        role: "admin",
    },
    {
        email: "jan07160716@gmail.com",
        role: "admin",
    },
    {
        email: "clairema@thebarkingdog-games.com",
        role: "admin",
    },
    {
        email: "dean@barkingdog.ai",
        role: "admin",
    },
    {
        email: "aiamaze.test@outlook.com",
        role: "admin",
    },
    {
        email: "dragonball880630@gmail.com",
        role: "admin",
    },
    {
        email: "jasonwang@barkingdog.ai",
        role: "admin",
    },
    {
        email: "ray@barkingdog.ai",
        role: "admin",
    },
];
export const USER_PERMISSION_CONFIG: IUserPermission[] = [
    ...TEMP_ADMIN,
    {
        email: "AA24014B0103.aiamaze@outlook.com", // 勝典
        role: "user",
        projectLimits: null,
    },
    {
        email: "ac24001b0101.aiamaze@outlook.com",
        role: "user",
        projectLimits: 2,
    },
    {
        email: "SA24003b0101.aiamaze@outlook.com",
        role: "user",
        projectLimits: 50,
    },
    {
        email: "youngman.aiamaze@gmail.com",
        role: "user",
        projectLimits: 2,
    },
    {
        email: "AA240312b0101.aiamaze@outlook.com",
        role: "user",
        projectLimits: 5,
    },
    {
        email: "laa23001b0101aiamaze@gmail.com",
        role: "user",
        projectLimits: 2,
    },

    {
        email: "aa10020.aiamaze@outlook.com",
        role: "user",
        projectLimits: 3,
    },
    {
        email: "honmyue@barkingdog.ai",
        role: "user",
        projectLimits: 3,
    },
];
