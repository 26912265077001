import { Checkbox, FormControlLabel } from "@mui/material";

interface IProps {
    labelText: string;
    handleChange: () => void;
    defaultChecked: boolean;
}
const CheckBoxUnControlled = ({ labelText, handleChange, defaultChecked }: IProps) => {
    return (
        <FormControlLabel
            control={<Checkbox defaultChecked={defaultChecked} />}
            label={labelText}
            onChange={handleChange}
            onClick={(event) => event.stopPropagation()}
        />
    );
};

export default CheckBoxUnControlled;
