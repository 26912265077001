import { useTranslation } from "react-i18next";

import styles from "./Greeting.module.scss";

interface IProps {
    greetM: string;
    greetL: string;
}

const Greeting = ({ greetM, greetL }: IProps) => {
    const { t } = useTranslation();
    return (
        <div className={styles.greeting}>
            <div className={styles.hello}>{t(greetM)}</div>
            <div className={styles.welcome}>{t(greetL)}</div>
        </div>
    );
};
export default Greeting;
