import { Slider, SliderProps } from "@mui/material";
import { Controller, FieldValues, useFormContext } from "react-hook-form";

interface IProps extends SliderProps {
    marks: { label: number; value: number }[];
    min: number;
    max: number;
    step: number;
    defaultValue?: number;
}
export const MSlider = (inputProps: IProps) => {
    const { min, max, step, defaultValue, marks, onChange, ...otherProps } = inputProps;
    return (
        <Slider
            min={min}
            max={max}
            step={step}
            defaultValue={defaultValue}
            // marks={marks}
            onChange={onChange}
            {...otherProps}
        />
    );
};

interface IFormProps extends IProps {
    name: string;
}

const FormSlider = (inputProps: IFormProps) => {
    const { name, marks, ...otherProps } = inputProps;
    const { control } = useFormContext<FieldValues>();
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error }, formState }) => {
                return <MSlider marks={marks} value={value} onChange={onChange} {...otherProps} />;
            }}
        />
    );
};
export default FormSlider;
