import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { MainTabs } from "../../components/tab";
import { ILinkTabProps } from "../../types/interfaces";
import { isProduction } from "../../utils";

const prodRoute = [
    { labelKey: "SALES.Category", href: "category" },
    { labelKey: "SALES.Shared_Spec", href: "shared-spec" },
    { labelKey: "SALES.Shared_Spec_Category", href: "shared-spec-category" },
    { labelKey: "SALES.Extra_Info", href: "extra-info" },
    { labelKey: "SALES.ItemManage", href: "item", initHref: "" },
];

const tabRoutes: ILinkTabProps[] = isProduction ? prodRoute : [...prodRoute];

const ItemTabs = () => {
    const navigate = useNavigate();

    const { pathname } = useLocation();

    const pathnameSplit = pathname?.split("/");
    const currentRoute = pathnameSplit[pathnameSplit?.length - 1] || "";

    const [currentTab, setCurrentTab] = useState(0);

    const findIdx = tabRoutes.findIndex((r) => r.href === currentRoute);

    const handleChange = (newValue: number) => {
        setCurrentTab(newValue);
    };

    useEffect(() => {
        // if findIdx is not -1, means it's project dashboard index page, show current tab === Dashboard
        if (findIdx !== -1) {
            setCurrentTab(findIdx);
            return;
        }
        setCurrentTab(0);
    }, [findIdx, currentRoute]);
    return (
        <>
            <MainTabs
                currentTab={currentTab}
                handleChange={handleChange}
                tabsOnClick={(tabRoute) => {
                    if (tabRoute.initHref) {
                        navigate(`/sale/item/${tabRoute.initHref}`);
                    } else if (tabRoute.initHref === "") {
                        navigate("/sale/item");
                    } else {
                        navigate(`/sale/item/${tabRoute.href}`);
                    }
                }}
                tabRoutes={tabRoutes}
                isPill={true}
            />
        </>
    );
};

export default ItemTabs;
