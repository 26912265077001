import { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { customToast } from "../../../components/notify/NotifyContainer";
import { useGetCharacterQuery } from "../services/charactersApiSlice";

const imageMimeType = /image\/(png|jpg|jpeg)/i;

interface IProps {
    handleSetAvatarFile: (avatarFile: File) => void;
    avatarFile: File | null;
}
const useAvatarInput = ({ handleSetAvatarFile, avatarFile }: IProps) => {
    const { characterId } = useParams();
    const [avatarUrl, setAvatarUrl] = useState<string | null>(null);

    //>  ============== API ==============
    const { data: cInfo } = useGetCharacterQuery({ id: characterId as string }, { skip: !characterId });

    const oldAvatarUrl = cInfo?.data?.character?.avatar_file_name;

    const handleChangeAvatar = (e: ChangeEvent<HTMLInputElement>): void => {
        const { files } = e.target;
        if (!files) return;

        if (!files[0].type.match(imageMimeType)) {
            customToast.error("Image type is not valid.");
            return;
        }
        handleSetAvatarFile(files[0]);
    };

    useEffect(() => {
        let fileReader: FileReader | undefined;
        let isCancel: boolean;
        if (avatarFile) {
            fileReader = new FileReader();
            fileReader.onload = (e: ProgressEvent<FileReader>) => {
                //const { result } = e.target;
                if (typeof e?.target?.result === "string" && !isCancel) {
                    setAvatarUrl(e.target.result);
                }
            };
            fileReader.readAsDataURL(avatarFile);
        }
        return () => {
            isCancel = true;
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        };
    }, [avatarFile]);

    return {
        oldAvatarUrl,
        avatarUrl,
        handleChangeAvatar,
    };
};

export default useAvatarInput;
