import { KeyboardEventHandler, useState } from "react";

import { IFilter } from "../../../types/interfaces/interface";

interface IProps {
    isCaseSensitive: boolean;
}
const MOCK_OPTIONS = ["1", "2", "3"];
const useSearch = ({ isCaseSensitive }: IProps) => {
    const [searchInput, setSearchInput] = useState<string>("");
    const [selectedVal, setSelectedVal] = useState<string>(MOCK_OPTIONS[0]);
    const searchOperator = isCaseSensitive ? "like" : "ilike";
    const handleSearch = (input: IFilter[]) => {
        //TODO: call API
        alert(JSON.stringify(input));
    };
    const handleClick = () => {
        if (searchInput.trim().length === 0) {
            handleSearch([
                { field: "content", operator: "ilike", value: "" },
                { field: "select", operator: "ilike", value: selectedVal },
            ]);
            return;
        }
        handleSearch([
            { field: "content", operator: searchOperator, value: searchInput.trim() },
            { field: "select", operator: searchOperator, value: selectedVal },
        ]);
        setSearchInput("");
        setSelectedVal(MOCK_OPTIONS[0]);
    };
    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
        if (event.key !== "Enter") return;
        if (searchInput.trim().length > 0) {
            handleSearch([
                { field: "content", operator: searchOperator, value: searchInput.trim() },
                { field: "select", operator: searchOperator, value: selectedVal },
            ]);
            setSearchInput("");
            return;
        }
        handleSearch([
            { field: "content", operator: "ilike", value: "" },
            { field: "select", operator: "ilike", value: selectedVal },
        ]);
    };
    return {
        handleSearch,
        handleClick,
        handleKeyDown,
        searchInput,
        selectedVal,
        setSearchInput,
        setSelectedVal,
    };
};
export default useSearch;
