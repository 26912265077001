import { useTranslation } from "react-i18next";
import { object, string } from "yup";

import { PROJECT_DESC_LIMIT } from "../constants";

export const ProjectInfoSchema = () => {
    const { t } = useTranslation();

    const schema = {
        id: string().required(),
        title: string().required(`${t("COMMON.Required_Input")}`),
        description: string()
            .required(`${t("COMMON.Required_Input")}`)
            .max(PROJECT_DESC_LIMIT, `${t("COMMON.Word_Limit")}  ${PROJECT_DESC_LIMIT.toString()} `),
        image_url: string().nullable(),
    };

    return object().shape(schema);
};

export const projectNameSchema = (isInit: boolean) => {
    const schema = {
        ...(!isInit && { project_title: string().required() }),
    };

    return object().shape(schema);
};
