import { IRoleResourceRestriction } from "../types";

export const ROLE_RESOURCE_RESTRICTIONS: IRoleResourceRestriction[] = [
    {
        roles: ["user", "enterprise_user"],
        resource: "project",
        action: "create",
    },
    {
        roles: ["user", "enterprise_user"],
        resource: "gptModel",
        action: "update",
    },
    {
        roles: ["user"],
        resource: "demo",
        action: "view",
    },
    {
        roles: ["user", "enterprise_user"],
        resource: "chatWidgetSetting",
        action: "update",
    },
];
