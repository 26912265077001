import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

import { useGetCharacterQuery } from "../services";
import { GPT_MODEL_TYPES, ICharacterSettingInput } from "../types";
import { CharacterSettingSchema } from "../validations/character";

interface IProps {
    characterId: string | undefined;
}

const useCharacterSettingForm = ({ characterId }: IProps) => {
    const { data: cInfo, isLoading: cIsLoading } = useGetCharacterQuery(
        { id: characterId as string },
        { skip: !characterId }
    );
    const defaultValues: ICharacterSettingInput = {
        gpt_model_id: cInfo?.data.character.gpt_model.toString() as GPT_MODEL_TYPES | null,
    };

    const gptModel = cInfo?.data?.character?.gpt_model ?? null;

    const methods = useForm<ICharacterSettingInput>({
        defaultValues,
        resolver: yupResolver(CharacterSettingSchema()),
    });

    useEffect(() => {
        if (gptModel !== null) {
            methods.reset({
                gpt_model_id: gptModel.toString() as GPT_MODEL_TYPES | null,
            });
        }
    }, [gptModel, methods]);
    return {
        methods,
        defaultValues,
        cIsLoading,
    };
};

export default useCharacterSettingForm;
