import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { customToast } from "../../../components/notify";
import { FORM_STATE_OPTIONS } from "../../../constants";
import { AMAZE_WEBSITE_URL } from "../../../types/enum";
import { checkObjValueExists } from "../../../utils";
import { useGetVoicesByLanguageQuery } from "../../voice/services";
import { KIOSK_DEFAULT_VOICE, LOCALE_OPTIONS } from "../constants";
import { useGetAllCVoicesQuery, useUpdateCVoiceMutation } from "../services";
import { ICharacterFormInput, IVoice } from "../types";
import { VoiceSettingSchema } from "../validations";

const defaultValues = {
    voice_id: "",
    pitch: 1,
    speed: 1,
    language: "",
    gender: "male",
    voice_text: "",
};

const useCharacterVoiceSettings = () => {
    const { projectId, characterId } = useParams();
    const { t } = useTranslation();
    const methods = useForm<ICharacterFormInput>({
        defaultValues,
        resolver: yupResolver(VoiceSettingSchema()),
        mode: "all",
    });
    const { reset } = methods;

    const voiceQuery = {
        language: methods.watch("language"),
        gender: methods.watch("gender"),
    };

    // ================== API ==================
    const { data: voices, isLoading: vLoading } = useGetVoicesByLanguageQuery(voiceQuery, {
        skip: !voiceQuery.language || !voiceQuery.gender,
    });

    //取得當前的腳色所有語音資料
    const { data: characterVoiceData } = useGetAllCVoicesQuery({ character_id: characterId as string });
    const [updateCharacterVoice, { isLoading: uVLoading, isSuccess: uVSuccess }] = useUpdateCVoiceMutation();

    // 預設先拿voiceData 的第一筆資料
    const firstCharacterVoiceData = characterVoiceData?.data?.[0];

    const processLanguageOptions = LOCALE_OPTIONS.map((item) => {
        if (characterVoiceData?.data?.map((v) => v.language).includes(item.value)) {
            return item;
        }
        return { ...item, label: `${item.label} (Unset)` };
    });
    const defaultLangVoice = KIOSK_DEFAULT_VOICE.find(
        (d) => d.code === methods.watch("language") && d.gender.toLowerCase() === methods.watch("gender")
    );
    const voicesOptions = useMemo(() => {
        return (
            voices?.data?.map((item) => {
                const isDefault = item.azure_voice_short_name === defaultLangVoice?.voice;
                return {
                    label: isDefault ? `${item.voice_name} (${t("COMMON.SystemDefault")})` : item.voice_name,
                    value: item.id,
                };
            }) ?? []
        );
    }, [defaultLangVoice?.voice, t, voices?.data]);

    const audioPlayerProp = {
        language: methods.watch("language"),
        gender: methods.watch("gender"),
        pitch: methods.watch("pitch"),
        speed: methods.watch("speed"),
        short_name: voices?.data?.find((v) => v.id === methods.watch("voice_id"))?.azure_voice_short_name || "",
    };

    //>  ============== HANDLER ==============

    const handleUpsert = async (data: ICharacterFormInput) => {
        if (characterId && projectId) {
            const audioReq = {
                character_id: characterId || "",
                project_id: projectId,
                pitch: data.pitch,
                speed: data.speed,
                voice_id: data.voice_id,
                voice_text: data.voice_text || "",
            };
            try {
                const res = await updateCharacterVoice(audioReq).unwrap();

                const updateFormData = {
                    ...data,
                    voice_id: res.data.source_voice_id,
                };
                //setFormData(updateFormData);
                methods.reset(updateFormData);
            } catch (err) {
                customToast.error("update failed");
            }
        }
    };

    const findVoiceDataFromApi = ({
        selectedId,
        selectedLanguage,
    }: {
        selectedId?: string;
        selectedLanguage?: string;
    }) => {
        if (selectedId) {
            return characterVoiceData?.data?.find((v: IVoice) => v.source_voice_id === selectedId);
        }
        if (selectedLanguage) {
            return characterVoiceData?.data?.find((v: IVoice) => v.language === selectedLanguage);
        }
    };

    const handleFormSubmit: SubmitHandler<ICharacterFormInput> = async (data) => await handleUpsert(data);

    const handleChangeLanguage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedLanguage = event.target.value;
        const voiceDataFromApi = findVoiceDataFromApi({ selectedLanguage });
        let temp = {
            ...defaultValues,
            language: event.target.value,
        };
        if (voiceDataFromApi) {
            temp = {
                ...temp,
                voice_id: voiceDataFromApi.source_voice_id,
                pitch: voiceDataFromApi.pitch,
                speed: voiceDataFromApi.speed,
                gender: voiceDataFromApi.gender,
                voice_text: voiceDataFromApi.voice_text,
            };
        }
        methods.reset(temp, { keepDirty: true, keepTouched: true });
    };
    const handleChangeGender = (event: React.ChangeEvent<HTMLInputElement>) => {
        methods.setValue("gender", event.target.value, FORM_STATE_OPTIONS);
        methods.setValue("speed", 1, FORM_STATE_OPTIONS);
        methods.setValue("pitch", 1, FORM_STATE_OPTIONS);
    };

    const handleChangeVoiceId = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedVoiceId = event.target.value;
        const voiceDataFromApi = findVoiceDataFromApi({ selectedId: selectedVoiceId });

        // 選取原本儲存的腳色，會帶入語音資料
        methods.setValue("voice_id", event.target.value, FORM_STATE_OPTIONS);
        methods.setValue("voice_id", selectedVoiceId || "", FORM_STATE_OPTIONS);
        methods.setValue("speed", voiceDataFromApi?.speed || 1, FORM_STATE_OPTIONS);
        methods.setValue("pitch", voiceDataFromApi?.pitch || 1, FORM_STATE_OPTIONS);
    };

    const handleUpgrade = () => {
        window.open(AMAZE_WEBSITE_URL, "_blank");
    };

    // 1. API 拿到 voiceData 後，將首筆資料填入 form，但在儲存資料後不重新渲染
    useEffect(() => {
        if (firstCharacterVoiceData) {
            reset({
                pitch: firstCharacterVoiceData.pitch,
                speed: firstCharacterVoiceData.speed,
                gender: firstCharacterVoiceData.gender,
                language: firstCharacterVoiceData.language,
                voice_text: firstCharacterVoiceData.voice_text,
                voice_id: firstCharacterVoiceData.source_voice_id,
            });
        }
    }, [firstCharacterVoiceData, reset]);

    useEffect(() => {
        if (uVSuccess) {
            customToast.success("successfully updated");
        }
    }, [uVSuccess, methods]);

    return {
        pageLoading: vLoading,
        handleFormSubmit,
        methods,
        voicesOptions,
        handleChangeLanguage,
        handleChangeGender,
        handleChangeVoiceId,
        handleUpgrade,
        canTestVoice: checkObjValueExists(audioPlayerProp),
        audioPlayerProp,
        btnLoading: uVLoading,
        processLanguageOptions,
    };
};
export default useCharacterVoiceSettings;
