import { ArrowUpward } from "@mui/icons-material";
import { Container } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";

import { scrollToTop } from "../../utils";
import MButton from "../button/MButton";
import styles from "./MainWrapper.module.scss";

interface IProps {
    children: ReactNode;
}
const MainWrapper = ({ children }: IProps) => {
    const [isBtnTopVisible, setIsBtnTopVisible] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const currentY = window.scrollY;
            const windowHeight = window.innerHeight * 0.4;

            if (currentY > windowHeight) {
                setIsBtnTopVisible(true);
            } else {
                setIsBtnTopVisible(false);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return;
    }, []);

    return (
        <Container className={styles.container} sx={{ pt: 7.3, mb: 8 }}>
            {children}
            <div className={styles.btnContainer}>
                {isBtnTopVisible && (
                    <MButton
                        variant='contained'
                        className={styles.sideBtn}
                        btnText=''
                        icon={<ArrowUpward />}
                        onClick={scrollToTop}
                        color='info'
                    ></MButton>
                )}
            </div>
        </Container>
    );
};
export default MainWrapper;
