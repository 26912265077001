import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { MButton } from "../../../../components/button";
import { RegularContainer } from "../../../../components/container";

const ChatroomPluginSection = () => {
    const { projectId, characterId } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const path = `/projects/${projectId}/characters/${characterId}/bot-settings/web-widget`;

    return (
        <RegularContainer headingTitle={t("CHATROOM.PLUGIN_TITLE")}>
            <MButton btnText={t("CHATROOM.Plugin_BTN_SET")} handleClick={() => navigate(path)} />
        </RegularContainer>
    );
};

export default ChatroomPluginSection;
