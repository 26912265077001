import React from "react";

import ItemRoutes from "./item";

const SaleAdminLangPage = React.lazy(() => import("../../pages/sales/SaleAdminLangPage"));
const EquipmentPage = React.lazy(() => import("../../pages/sales/EquipmentPage"));

const SaleRoutes = [
    {
        path: "",
        children: [
            {
                path: "",
                children: [
                    {
                        path: "item",
                        children: ItemRoutes,
                    },
                    {
                        path: "admin-language",
                        element: <SaleAdminLangPage />,
                    },
                    {
                        path: "equipment",
                        element: <EquipmentPage />,
                    },
                ],
            },
        ],
    },
];

export default SaleRoutes;
