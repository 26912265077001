import React from "react";

const KnowledgeExportImportPage = React.lazy(() => import("../pages/knowledges/KnowledgeExportImportPage"));
const KnowledgeLayout = React.lazy(() => import("../features/characters/components/layout/KnowledgeLayout"));

const KnowledgeRecordLayout = React.lazy(
    () => import("../features/characters/components/layout/KnowledgeRecordLayout")
);
const MineKnowledgePage = React.lazy(() => import("../pages/knowledges/MineKnowledgePage"));

const CreateKnowledgePage = React.lazy(() => import("../pages/knowledges/CreateKnowledgePage"));
// const PublicKnowledgePage = React.lazy(() => import("../pages/knowledges/PublicKnowledgePage"));
const EditKRecordPage = React.lazy(() => import("../pages/knowledges/record/EditKRecordPage"));
const ManualGeneratorPage = React.lazy(() => import("../pages/knowledges/record/ManualGeneratorPage"));
const SmartGeneratorPage = React.lazy(() => import("../pages/knowledges/record/SmartGeneratorPage"));
const KnowledgeRecordsPage = React.lazy(() => import("../pages/knowledges/record/KnowledgeRecordsPage"));
const KnowledgeInusePage = React.lazy(() => import("../pages/knowledges/KnowledgeInusePage"));
const KnowledgeSettingsPage = React.lazy(() => import("../pages/knowledges/KnowledgeSettingsPage"));

const KnowledgeRoutes = [
    {
        element: <KnowledgeLayout />,
        path: "",
        children: [
            {
                element: <KnowledgeInusePage />,
                path: "inuse-knowledge",
            },
            {
                element: <MineKnowledgePage />,
                path: "mine-knowledge",
            },
            {
                element: <KnowledgeExportImportPage />,
                path: "export-import-knowledge",
            },
            //! [2024_0614]這裡暫時用不到，但為確保以後需要的時候不用重新開發，所以先註解保留
            // {
            //     element: <PublicKnowledgePage />,
            //     path: "public-knowledge",
            // },
        ],
    },
    {
        element: <CreateKnowledgePage />,
        path: "create-knowledge",
    },
    {
        path: ":knowledgeId",
        element: <KnowledgeRecordLayout />,
        children: [
            {
                element: <SmartGeneratorPage />,
                path: "smart-generator",
            },
            {
                element: <ManualGeneratorPage />,
                path: "manual-generator",
            },
            {
                element: <KnowledgeSettingsPage />,
                path: "knowledge-settings",
            },
            {
                element: <KnowledgeRecordsPage />,
                path: "knowledge-records",
            },
        ],
    },
    {
        path: ":knowledgeId",
        children: [
            {
                path: ":recordId",
                children: [
                    {
                        element: <EditKRecordPage />,
                        path: "edit-knowledge-record",
                    },
                ],
            },
        ],
    },
];

export default KnowledgeRoutes;
