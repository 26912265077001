import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Button } from "@mui/material";
import React from "react";

import useAvatarInput from "../../hooks/useAvatarInput";
import CharacterAvatar from "./CharacterAvatar";
import styles from "./CharacterForm.module.scss";

interface IProps {
    handleSetAvatarFile: (avatarFile: File) => void;
    avatarFile: File | null;
}
const AvatarInput = ({ handleSetAvatarFile, avatarFile }: IProps) => {
    const { oldAvatarUrl, avatarUrl, handleChangeAvatar } = useAvatarInput({ handleSetAvatarFile, avatarFile });
    return (
        <div style={{ width: "100%", height: "100%" }}>
            <CharacterAvatar isForm={true} isCircle={true} avatarUrl={oldAvatarUrl ? oldAvatarUrl : avatarUrl} />
            <Button
                variant='contained'
                sx={{ borderRadius: "100%" }}
                color='info'
                component='label'
                className={styles.avatarBtn}
            >
                <input type='file' id='file' hidden onChange={handleChangeAvatar} />
                <EditRoundedIcon />
            </Button>
        </div>
    );
};

export default AvatarInput;
