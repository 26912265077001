import { Pagination } from "@mui/material";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { CheckBoxUnControlled } from "../../../components/form/checkbox";
import { PageLoader } from "../../../components/loader";
import { MAlert } from "../../../components/notify";
import { SearchBox } from "../../../components/searchBox";
import { useInuseList } from "../hooks";
import styles from "./KnowledgeList.module.scss";

const InuseList = () => {
    const { t } = useTranslation();

    const {
        pageIsLoading,
        showKnowledgeList,
        handleCheckbox,
        handleSearch,
        handleClickKnowledge,
        pageCount,
        currentPage,
        handlePageChange,
    } = useInuseList();

    return (
        <>
            <PageLoader loading={pageIsLoading} />
            <div className={styles.wrapper}>
                <div className={styles.searchWrapper}>
                    <SearchBox
                        onSearch={handleSearch}
                        placeHolder={t("KNOWLEDGE.Search_KnowledgeTitle")}
                        searchField='title'
                        isCaseSensitive={true}
                    />{" "}
                </div>
                <div className={styles.list}>
                    <div className={styles.listContent}>
                        {showKnowledgeList.length > 0 ? (
                            showKnowledgeList.map((item) => {
                                return (
                                    <div key={item.id} className={styles.listItem}>
                                        <div
                                            className={clsx(styles.context, styles.cursor)}
                                            onClick={() => handleClickKnowledge(item.id)}
                                        >
                                            <div className={styles.knowledgeTitle}>{item.title}</div>
                                            <div className={styles.knowledgeDesc}>{item.description}</div>
                                        </div>
                                        <div>
                                            <CheckBoxUnControlled
                                                labelText={t("KNOWLEDGE.Knowledge_inuse")}
                                                handleChange={() => handleCheckbox(item)}
                                                defaultChecked={true}
                                            />
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <MAlert severity='info' sx={{ bgcolor: "transparent" }}>
                                {t("KNOWLEDGE.No_Knowledge")}
                            </MAlert>
                        )}
                    </div>
                    <div className={styles.paginationWrapper}>
                        <Pagination
                            count={pageCount}
                            page={currentPage}
                            onChange={handlePageChange}
                            showFirstButton
                            showLastButton
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default InuseList;
