import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { RegularContainer } from "../../components/container";
import { InnerTabs } from "../../components/tab";
import { ILinkTabProps } from "../../types/interfaces";

const tabRoutes: ILinkTabProps[] = [
    { labelKey: "ANALYSIS.Data_Message_Number", href: "message-number" },
    { labelKey: "ANALYSIS.Data_Message_Analysis", href: "message-analysis" },
];

const MessageTabs = () => {
    const navigate = useNavigate();

    const location = useLocation();
    // const { state } = location;
    const { projectId } = useParams();

    const [currentTab, setCurrentTab] = useState(0);

    const handleChange = (newValue: number) => {
        setCurrentTab(newValue);
    };

    const pathnameSplit = location?.pathname?.split("/");
    const currentRoute = pathnameSplit[pathnameSplit?.length - 1] || "";

    const findIdx = tabRoutes.findIndex((r) => {
        return r.href === currentRoute;
    });

    useEffect(() => {
        if (findIdx !== -1) {
            setCurrentTab(findIdx);
            return;
        }
        setCurrentTab(0);
    }, [findIdx, currentRoute]);

    return (
        <RegularContainer sx={{ mt: 0, paddingY: 0 }}>
            <InnerTabs
                currentTab={currentTab}
                handleChange={handleChange}
                tabRoutes={tabRoutes}
                tabsOnClick={(tabRoute) => {
                    navigate(`/projects/${projectId}/data-analysis/${tabRoute.href}`, {
                        state: projectId,
                    });
                }}
            />
        </RegularContainer>
    );
};

export default MessageTabs;
