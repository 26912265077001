import clsx from "clsx";
import { ReactNode } from "react";
import { useParams } from "react-router-dom";

import { Chatroom } from "../../features/characters/components/chatroomSetting";
import { useGetAllProjectCharactersQuery } from "../../features/characters/services";
import styles from "./PageBox.module.scss";

interface IProps {
    hasChatBox?: boolean;
    children: ReactNode;
    styleName?: string;
}

const PageBox = ({ hasChatBox = true, children, styleName }: IProps) => {
    const { characterId, projectId } = useParams();
    const allowSelectCharacter = !characterId;

    const { data: projectCharacters } = useGetAllProjectCharactersQuery(
        { page: 1, limit: 100, project_id: projectId as string },
        { skip: !(allowSelectCharacter && projectId) }
    );

    const defaultCharacterId = allowSelectCharacter ? projectCharacters?.data?.records?.[0]?.id : characterId;

    return (
        <div className={styleName}>
            <div className={styles.pageBox}>
                <div className={clsx(styles.contentBox, { [styles.maxWidth]: hasChatBox })}>{children}</div>

                {hasChatBox && (
                    <div className={styles.chatBox}>
                        <Chatroom characterId={defaultCharacterId} chatBoxClass={styles.chatWidgetMobile} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default PageBox;
