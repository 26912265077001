import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ITEM_IN_PAGE } from "../components/item/constants/constants";
import { IFilter, ISaleItem, priceOrderType, TagType, TFilterActionType } from "../types";

export const mockItem: ISaleItem[] = [
    { type: "夏季冷飲", name: "紅茶", imgUrl: "https://picsum.photos/id/500/144/130", price: 20 },
    { type: "熱門推薦", name: "奶茶", imgUrl: "https://picsum.photos/id/501/144/130", price: 30 },
    { type: "冬季熱飲", name: "綠茶", imgUrl: "https://picsum.photos/id/502/144/130", price: 40 },
    { type: "熱門推薦", name: "青茶", imgUrl: "https://picsum.photos/id/504/144/130", price: 40 },
    { type: "冷飲", name: "花茶", imgUrl: "https://picsum.photos/id/503/144/130", price: 50 },
    { type: "夏季冷飲", name: "紅茶", imgUrl: "https://picsum.photos/id/500/144/130", price: 20 },
    { type: "熱門推薦", name: "奶茶", imgUrl: "https://picsum.photos/id/501/144/130", price: 30 },
    { type: "冬季熱飲", name: "綠茶", imgUrl: "https://picsum.photos/id/502/144/130", price: 40 },
    { type: "冷飲", name: "花茶", imgUrl: "https://picsum.photos/id/503/144/130", price: 50 },
    { type: "夏季冷飲", name: "紅茶", imgUrl: "https://picsum.photos/id/500/144/130", price: 20 },
    { type: "熱門推薦", name: "奶茶", imgUrl: "https://picsum.photos/id/501/144/130", price: 30 },
    { type: "冬季熱飲", name: "綠茶", imgUrl: "https://picsum.photos/id/502/144/130", price: 40 },
    { type: "冷飲", name: "花茶", imgUrl: "https://picsum.photos/id/503/144/130", price: 50 },
    { type: "夏季冷飲", name: "紅茶", imgUrl: "https://picsum.photos/id/500/144/130", price: 20 },
    { type: "熱門推薦", name: "奶茶", imgUrl: "https://picsum.photos/id/501/144/130", price: 30 },
    { type: "冬季熱飲", name: "綠茶", imgUrl: "https://picsum.photos/id/502/144/130", price: 40 },
    { type: "冷飲", name: "花茶", imgUrl: "https://picsum.photos/id/503/144/130", price: 50 },
    { type: "夏季冷飲", name: "紅茶", imgUrl: "https://picsum.photos/id/500/144/130", price: 20 },
    { type: "熱門推薦", name: "奶茶", imgUrl: "https://picsum.photos/id/501/144/130", price: 30 },
    { type: "冬季熱飲", name: "綠茶", imgUrl: "https://picsum.photos/id/502/144/130", price: 40 },
    { type: "冷飲", name: "花茶", imgUrl: "https://picsum.photos/id/503/144/130", price: 50 },
    { type: "熱門推薦", name: "奶茶", imgUrl: "https://picsum.photos/id/501/144/130", price: 30 },
    { type: "冬季熱飲", name: "綠茶", imgUrl: "https://picsum.photos/id/502/144/130", price: 40 },
    { type: "冷飲", name: "花茶", imgUrl: "https://picsum.photos/id/503/144/130", price: 50 },
    { type: "熱門推薦", name: "奶茶", imgUrl: "https://picsum.photos/id/501/144/130", price: 30 },
    { type: "冬季熱飲", name: "綠茶", imgUrl: "https://picsum.photos/id/502/144/130", price: 40 },
    { type: "冷飲", name: "花茶", imgUrl: "https://picsum.photos/id/503/144/130", price: 50 },
];
interface IProps {
    dispatchFilterState: React.Dispatch<TFilterActionType>;
    filterState: IFilter;
}

const useFilter = ({ dispatchFilterState, filterState }: IProps) => {
    const [filterOpen, setFilterOpen] = useState(false);
    const [filterItems, setFilterItems] = useState<ISaleItem[]>([]);
    const [slicedData, setSlicedData] = useState<ISaleItem[][]>([]); // 資料切片
    const { t } = useTranslation();

    const handleOrderText = (priceOrder: priceOrderType) => {
        const orderTypeDict = [
            {
                name: `${t(`SALES.PriceDownToUp`)}`,
                value: "asc",
            },
            {
                name: `${t(`SALES.PriceUpToDown`)}`,
                value: "desc",
            },
        ];
        const matchPriceOrder = orderTypeDict.find((o) => o.value === priceOrder)?.name || "";
        return matchPriceOrder;
    };

    // 刪除filter的tag
    const handleFilterDelete = (tag: TagType) => {
        dispatchFilterState({ type: "DELETE_FILTER", payload: tag });
    };

    // 切換語言
    const handleLanguageChange = (event: ChangeEvent<HTMLSelectElement>) => {
        dispatchFilterState({ type: "SET_FILTER_LANGUAGE", payload: event.target.value });
    };

    // 重置選項
    const handleResetOption = () => {
        dispatchFilterState({ type: "RESET_FILTER" });
    };

    // 改變radio選項
    const handleRadioOption = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        if (name === "分類") {
            dispatchFilterState({ type: "SET_FILTER_CATEGORY_TYPE", payload: value });
            return;
        }
        if (name === "價格") {
            dispatchFilterState({
                type: "SET_FILTER_PRICE_ORDER",
                payload: value === `${t(`SALES.PriceDownToUp`)}` ? "asc" : "desc",
            });
            return;
        }
    };

    // 價格range
    const handlePriceRange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === "min") {
            dispatchFilterState({
                type: "SET_FILTER_PRICE_RANGE",
                payload: {
                    ...filterState.priceRange,
                    min: parseInt(value),
                },
            });
        }
        if (name === "max") {
            dispatchFilterState({
                type: "SET_FILTER_PRICE_RANGE",
                payload: {
                    ...filterState.priceRange,
                    max: parseInt(value),
                },
            });
        }
    };

    // 將 mockData 切成份 （之後要拿掉，因為之後是從API那邊拿過來就已經切好了）
    const handleSliceMockData = (item: ISaleItem[], groupSize: number) => {
        const result = [];
        for (let i = 0; i < item.length; i += groupSize) {
            result.push(item.slice(i, i + groupSize));
        }
        return result;
    };

    // 過濾功能
    useEffect(() => {
        // 每次過濾前清空 filterItems
        setFilterItems([]);

        // 過濾商品名稱
        const filterByItemName = () => {
            if (filterState.itemName) {
                const filteredItems = mockItem.filter((item) =>
                    filterState.itemName ? item.name.includes(filterState.itemName) : false
                );
                setFilterItems(filteredItems);
            }
        };
        // 過濾分類
        const filterByCategory = () => {
            if (filterState.categoryType) {
                const filteredItems = mockItem.filter((item) =>
                    filterState.categoryType ? item.type.includes(filterState.categoryType) : false
                );
                setFilterItems(filteredItems);
            }
        };
        // 同時過濾商品名稱及category
        const filterByCategoryAndItemName = () => {
            const filteredItems = mockItem.filter(
                (item) =>
                    filterState.categoryType &&
                    item.type.includes(filterState.categoryType) &&
                    filterState.itemName &&
                    item.name.includes(filterState.itemName)
            );
            setFilterItems(filteredItems);
        };

        // 根據條件進行過濾
        if (filterState.itemName && filterState.categoryType) {
            filterByCategoryAndItemName();
        } else if (filterState.itemName) {
            filterByItemName();
        } else if (filterState.categoryType) {
            filterByCategory();
        } else {
            setFilterItems(mockItem); // 如果沒有篩選條件，顯示所有項目
        }
    }, [filterState]);

    // 切割過濾後的資料
    useEffect(() => {
        const groupedData = handleSliceMockData(filterItems, ITEM_IN_PAGE);
        setSlicedData(groupedData);
    }, [filterItems]);

    return {
        filterOpen,
        setFilterOpen,
        handleLanguageChange,
        slicedData,
        mockItem, // TODO:之後要拿掉
        handleRadioOption,
        handlePriceRange,
        handleResetOption,
        handleOrderText,
        handleFilterDelete,
    };
};

export default useFilter;
