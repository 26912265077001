import { SALE_API_URL } from "../../../constants";
import { apiSlice } from "../../../services/api/apiSlice";
import { ICheckSaleAccountRes, IGetSaleAccountRes, ILoginSaleAccountReq } from "../types/interfaces";

export const salesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        loginSaleAccount: builder.mutation<ICheckSaleAccountRes, ILoginSaleAccountReq>({
            query: (reqData) => ({
                url: `${SALE_API_URL}/login`,
                method: "POST",
                body: { ...reqData },
            }),
            invalidatesTags: ["Auth"],
        }),
        checkSaleAccount: builder.mutation<IGetSaleAccountRes, void>({
            query: () => ({
                url: `${SALE_API_URL}/account/me`,
            }),
            invalidatesTags: ["Auth"],
        }),
    }),
});

export const { useLoginSaleAccountMutation, useCheckSaleAccountMutation } = salesApiSlice;
