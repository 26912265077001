import { Delete } from "@mui/icons-material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { IconButton } from "@mui/material";
import { ReactNode, SyntheticEvent, useCallback, useEffect, useRef, useState } from "react";
import { Controller, useController, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { MIME_TYPES } from "../../constants";
import { mapMimeTypeKey } from "../../utils";
import { customToast } from "../notify/NotifyContainer";
import styles from "./FormUploadFile.module.scss";

interface IProps {
    limit: number;
    name: string;
    acceptTypes: string[];
    maxLimit?: number; // default = 10 * 1024 * 1024 => 10 MB
    children?: ReactNode;
}

const FormUploadFile = ({ children, limit, name, acceptTypes, maxLimit = 10 * 1024 * 1024 }: IProps) => {
    // * get user friendly file type text for UI
    const validTypes = Object.values(MIME_TYPES);
    const mapFileKeys = acceptTypes.map((item) => mapMimeTypeKey(item));
    const { t } = useTranslation();
    const wrapperRef = useRef<HTMLDivElement>(null);

    const [singleFile, setSingleFile] = useState<File[]>([]);

    const [fileList, setFileList] = useState<File[]>([]);
    const isMultiple = Math.floor(limit) > 1;

    const {
        control,
        formState: { isSubmitted },
        reset,
        resetField,
    } = useFormContext();
    const { field } = useController({ name, control });

    const onDragEnter = () => wrapperRef.current?.classList.add("dragover");
    const onDragLeave = () => wrapperRef.current?.classList.remove("dragover");

    const onFileChange = useCallback(
        (e: SyntheticEvent<EventTarget> | null) => {
            const target = e?.target as HTMLInputElement;
            if (!target?.files) {
                setFileList([]);
                setSingleFile([]);
                return;
            }

            if (limit === 1) {
                const newFile = Object.values(target.files).map((file: File) => file);
                const [file] = newFile;
                // * validate file types
                if (!acceptTypes.includes(file.type)) {
                    customToast.error(`Invalid File Types`);
                    return;
                }
                // * validate file limits
                if (file.size > maxLimit) {
                    customToast.error("File size over limit.");
                    return;
                }

                setSingleFile(newFile);
                field.onChange(file);
            }
            // TODO: multiple files upload not completed yet
            if (!isMultiple) return;
            const extractFiles = Object.values(target.files).map((file: File) => file);
            const isAllTypeValid = extractFiles.every((el) => validTypes.includes(el.type));
            const isUnderFileSizeLimitation = extractFiles.every((el) => el.size < maxLimit);
            if (!isAllTypeValid) {
                customToast.error("Please upload accept files");
                return;
            }
            if (!isUnderFileSizeLimitation) {
                customToast.error("File should not be larger than 10MB.");
                return;
            }
            const updatedList = [...fileList, ...extractFiles];
            if (updatedList.length > limit || extractFiles.length > limit) {
                return customToast.error(`File numbers must not be more than ${limit}`);
            }
            setFileList(updatedList);
            field.onChange(updatedList);
        },
        [field, limit, validTypes, maxLimit, fileList, isMultiple, acceptTypes]
    );

    //  remove files
    const filesRemove = (file: File) => {
        const updatedList = [...fileList];
        updatedList.splice(fileList.indexOf(file), 1);
        setFileList(updatedList);
    };

    const oneFileRemove = () => {
        onFileChange(null);
        setSingleFile([]);
        //! reset 自己的欄位就好
        resetField(name);
    };

    useEffect(() => {
        if (!isSubmitted) return;
        setFileList([]);
        setSingleFile([]);
    }, [isSubmitted, reset]);
    return (
        <>
            <div className={styles.wrapper}>
                <div
                    className={styles.uploadSection}
                    ref={wrapperRef}
                    onDragEnter={onDragEnter}
                    onDragLeave={onDragLeave}
                    onDrop={onDragLeave}
                >
                    <div className={styles.headingWrapper}>
                        <div>
                            <CloudUploadOutlinedIcon color='primary' sx={{ fontSize: 48 }} />
                        </div>
                        <div className='inputLabel'>{t("COMMON.Supported_files")}</div>
                        <div className='paragraph'>{mapFileKeys.join(", ")}</div>
                    </div>
                    <Controller
                        name={name}
                        // defaultValue=''
                        control={control}
                        render={({ field: { name, onBlur, ref } }) => (
                            <input
                                type='file'
                                name={name}
                                onBlur={onBlur}
                                ref={ref}
                                onChange={onFileChange}
                                multiple={isMultiple}
                                accept={acceptTypes.join(", ")}
                                onClick={(e) => ((e.target as HTMLInputElement).value = "")}
                                className={styles.input}
                            />
                        )}
                    />
                </div>
            </div>
            {children}
            {/* File list preview section */}
            {fileList.length > 0 || singleFile.length > 0 ? (
                <div className={styles.fileListWrapper}>
                    {(isMultiple ? fileList : singleFile).map((item, index) => {
                        return (
                            <div
                                key={index}
                                className={styles.fileList}
                                onClick={() => (isMultiple ? filesRemove(item) : oneFileRemove())}
                            >
                                <div className={styles.fileRow}>
                                    {/* <img src={docImg} alt='upload' /> */}
                                    <div className={styles.info}>
                                        <div className={styles.fileName}>{item.name}</div>
                                        {/* <div className={styles.fileSize}>{calcFileSize(item.size)}</div> */}
                                    </div>
                                </div>
                                <IconButton className={styles.actionIcon}>
                                    <Delete />
                                </IconButton>
                            </div>
                        );
                    })}
                </div>
            ) : null}
        </>
    );
};
export default FormUploadFile;
