import "react-image-crop/src/ReactCrop.scss";

import { Box, DialogActions, Modal } from "@mui/material";
import clsx from "clsx";
import { DependencyList, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactCrop, { Crop, PixelCrop } from "react-image-crop";
import { toast } from "react-toastify";

import pngBackground from "../../assets/images/pngBackground.png";
import MButton from "../button/MButton";
import { canvasPreview } from "./canvasPreview";
import styles from "./ImageCrop.module.scss";

interface IProps {
    handleToggle: () => void;
    openCrop: boolean;
    setOpenCrop: () => void;
    handleCancel: () => void;
    handleConfirm?: (file: File) => void;
    currentImage?: File;
    currentImageUrl?: string;
    fixAspect?: string;
    showFixPreview?: boolean;
    showFreePreview?: boolean;
    fixCropTitle?: string;
    freeCropTitle?: string;
}

const ImageCrop = ({
    currentImage,
    handleToggle,
    openCrop,
    setOpenCrop,
    handleCancel,
    handleConfirm,
    currentImageUrl,
    fixAspect,
    showFixPreview = false,
    showFreePreview = false,
    fixCropTitle = "RECORD.CropImageWidget",
    freeCropTitle = "RECORD.CropImageKiosk",
}: IProps) => {
    const { t } = useTranslation();
    const fileName = currentImage?.name || "croppedImage";
    const [crop, setCrop] = useState<Crop>({
        unit: "%",
        x: 0,
        y: 0,
        width: 100,
        height: 100,
    });
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const imgRef = useRef<HTMLImageElement>(null);
    const previewFreeCanvasRef = useRef<HTMLCanvasElement>(null);
    const previewWidgetCanvasRef = useRef<HTMLCanvasElement>(null);
    const isPreviewMode = !!currentImageUrl;

    const getCroppedImage = async (image: HTMLImageElement, crop: PixelCrop, fileName: string): Promise<File> => {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx?.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        const blob = await new Promise<Blob | null>((resolve) => {
            canvas.toBlob(resolve, currentImage?.type || "image/jpeg");
        });

        if (!blob) {
            throw new Error("Canvas is empty");
        }

        const newFile = new File([blob], fileName, { type: currentImage?.type || "image/jpeg" });
        return newFile;
    };

    const handleCropConfirm = async () => {
        if (!currentImage) {
            return null;
        }
        if (!completedCrop) {
            setOpenCrop();
        }
        if (handleConfirm && completedCrop && imgRef.current) {
            try {
                const croppedImage = await getCroppedImage(imgRef.current, completedCrop, fileName);
                handleConfirm(croppedImage);
            } catch (err) {
                toast.error("Error cropping image, please try again");
            }
        }
    };

    const useDebounceEffect = (fn: () => void, waitTime: number, deps: DependencyList = []) => {
        useEffect(() => {
            const timer = setTimeout(() => {
                fn();
            }, waitTime);

            return () => {
                clearTimeout(timer);
            };
        }, [fn, waitTime, deps]);
    };
    useDebounceEffect(
        async () => {
            if (completedCrop?.width && completedCrop?.height && imgRef.current) {
                const canvases = [previewWidgetCanvasRef.current, previewFreeCanvasRef.current];
                canvases.forEach((canvas) => {
                    if (canvas && imgRef.current) {
                        canvasPreview(imgRef.current, canvas, completedCrop);
                    }
                });
            }
        },
        100,
        [completedCrop]
    );
    return (
        <Modal open={openCrop} onClose={handleToggle}>
            <Box className={styles.modal}>
                {!isPreviewMode && (
                    <div className={styles.cropContainer}>
                        <div className={styles.cropToolWrap}>
                            <div className={styles.cropTool}>
                                <ReactCrop
                                    crop={crop}
                                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                                    onComplete={(c) => setCompletedCrop(c)}
                                    ruleOfThirds={true}
                                    minWidth={10}
                                    minHeight={10}
                                >
                                    <img
                                        className={styles.cropImg}
                                        ref={imgRef}
                                        alt='Crop me'
                                        src={currentImage && URL.createObjectURL(currentImage)}
                                    />
                                </ReactCrop>
                            </div>
                        </div>

                        <DialogActions className={styles.actionWrapper}>
                            <MButton
                                handleClick={() => {
                                    setOpenCrop();
                                    handleCancel();
                                }}
                                btnText={t("COMMON.Cancel")}
                                color='info'
                                type='button'
                                className='cancelBtn'
                            />
                            <MButton
                                handleClick={handleCropConfirm}
                                variant='contained'
                                type='button'
                                btnText={t("COMMON.Confirm")}
                                color='primary'
                                className='primaryBtn'
                            />
                        </DialogActions>
                    </div>
                )}

                <div className={isPreviewMode ? styles.flexPreviewMode : styles.previewContainer}>
                    <div className={styles.baseWrapper}>
                        {showFixPreview && (
                            <div className={styles.previewWrapper}>
                                <p className={styles.infoTitle}>{t(fixCropTitle)}</p>
                                <div
                                    className={clsx(
                                        styles.preview,
                                        { [styles.previewWidgetWarper]: !fixAspect },
                                        { [styles.ratio_1_1]: fixAspect === "1:1" },
                                        { [styles.ratio_4_3]: fixAspect === "4:3" },
                                        { [styles.ratio_banner]: fixAspect === "1480_698" }
                                    )}
                                >
                                    {completedCrop ? (
                                        <canvas
                                            ref={previewWidgetCanvasRef}
                                            style={{
                                                objectFit: "cover",
                                                width: "100%",
                                                height: "100%",
                                                backgroundRepeat: "repeat",
                                                backgroundImage: `url(${pngBackground})`,
                                            }}
                                        />
                                    ) : (
                                        <img
                                            className={styles.cropImg}
                                            alt='Crop me'
                                            src={currentImageUrl || (currentImage && URL.createObjectURL(currentImage))}
                                            style={{
                                                objectFit: "cover",
                                                width: "100%",
                                                height: "100%",
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                        {showFreePreview && (
                            <div className={styles.previewWrapper}>
                                <p className={styles.infoTitle}>{t(freeCropTitle)}</p>
                                <div className={clsx(styles.previewFreeWarper, styles.preview)}>
                                    {completedCrop ? (
                                        <canvas
                                            ref={previewFreeCanvasRef}
                                            style={{
                                                objectFit: "contain",
                                                width: "100%",
                                                height: "100%",
                                                objectPosition: "center",
                                                backgroundRepeat: "repeat",
                                                backgroundImage: `url(${pngBackground})`,
                                            }}
                                        />
                                    ) : (
                                        <img
                                            className={styles.cropImg}
                                            alt='Crop me'
                                            src={currentImageUrl || (currentImage && URL.createObjectURL(currentImage))}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    {isPreviewMode && (
                        <DialogActions className={styles.closeWrapper}>
                            <MButton
                                handleClick={() => {
                                    setOpenCrop();
                                }}
                                variant='contained'
                                type='button'
                                btnText={t("COMMON.Close")}
                                color='primary'
                                className='primaryBtn'
                            />
                        </DialogActions>
                    )}
                </div>
            </Box>
        </Modal>
    );
};

export default ImageCrop;
