import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ImageInput, ItemInput } from "../types";
import { ItemInputSchema } from "../validations/item";

const MOCK_ITEM = {
    name: "test",
    category: "test1",
    base_price: "100",
    hide_content: "test",
    additional_info: "test",
};

const MOCK_IMAGES = {
    main_image: "https://picsum.photos/id/501/144/130",
    banner_image: "https://picsum.photos/id/502/144/130",
    extension_image: "https://picsum.photos/id/503/144/130",
};

const defaultValues = {
    name: "",
    category: "",
    base_price: "",
    hide_content: "",
    additional_info: "",
};
const imgDefaultValues = {
    main_image: null,
    banner_image: null,
    extension_image: null,
};
interface IProps {
    isCreate: boolean;
    isOrder: boolean;
}
const useCreateItem = ({ isCreate, isOrder }: IProps) => {
    const [searchParams] = useSearchParams();
    const selectedLang = searchParams.get("lang") || "zh-TW";

    const navigate = useNavigate();
    const methods = useForm<ItemInput>({
        defaultValues: {
            ...(isCreate ? defaultValues : MOCK_ITEM),
        },
        resolver: yupResolver(ItemInputSchema(isCreate, isOrder)),
        mode: "all",
    });

    const imgMethods = useForm<ImageInput>({
        defaultValues: {
            ...imgDefaultValues,
        },
        mode: "onBlur",
    });
    const handleCancel = () => {
        methods.reset();
    };

    const handleImgFormSubmit = (data: ImageInput) => {
        imgMethods.handleSubmit((data) => {
            // console.log(data);
        });
    };

    const handleFormSubmit = (data: ItemInput) => {
        methods.handleSubmit((data) => {
            // console.log(data);
        });
    };

    const handleSave = () => {
        methods.handleSubmit((data) => {
            const reqData = {
                ...data,
                language: selectedLang,
            };
            //console.log("Item Form Data:", reqData);
            imgMethods.handleSubmit((imgData) => {
                alert(reqData);
                //console.log("Image Form Data:", imgData);
            })();
            navigate(`/sale/item`);
            methods.reset();
            imgMethods.reset();
        })();
    };
    return {
        methods,
        handleFormSubmit,
        handleCancel,
        imgMethods,
        handleImgFormSubmit,
        handleSave,
        MOCK_IMAGES,
    };
};

export default useCreateItem;
