import { useTranslation } from "react-i18next";

import { RegularContainer } from "../../../../../components/container";
import { DropdownBox } from "../../../../../components/dropDownBox";
import { useMessageSelect } from "../../../hooks";

const SocialDropdown = () => {
    const { handleFilterChange, category, socialOptions } = useMessageSelect();
    const { t } = useTranslation();

    return (
        <RegularContainer sx={{ pb: "2rem" }} headingTitle={t("ANALYSIS.Data_Social_Analysis")}>
            <DropdownBox
                sx={{ mb: 0 }}
                category={category}
                options={socialOptions}
                onChange={handleFilterChange}
                dropdownTitle='ANALYSIS.Social_Selector'
            ></DropdownBox>
        </RegularContainer>
    );
};
export default SocialDropdown;
