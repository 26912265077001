import clsx from "clsx";
import { CSSProperties } from "react";

import { ChatWidgetRoom } from "../../../../components/chatroom";
import { BouncingDots } from "../../../../components/loader";
import { AMAZE_DASHBOARD_WIDGET_CONFIG_FOR_APP, AMAZE_DASHBOARD_WIDGET_STYLE_OVERRIDES } from "../../../../constants";
import { CHAT_WIDGET_VERSION } from "../../../../types/enum";
import { useGenerateChatWidgetConfig } from "../../hooks";
import styles from "./Chatroom.module.scss";

interface IProps {
    characterId?: string;
    chatBoxClass: CSSProperties;
}
// 角色聊天室
const Chatroom = ({ characterId, chatBoxClass }: IProps) => {
    const widgetConfig = useGenerateChatWidgetConfig({
        characterId,
        customStyleOverrides: AMAZE_DASHBOARD_WIDGET_STYLE_OVERRIDES,
        selectorId: "chat-widget-mobile",
        configForApp: AMAZE_DASHBOARD_WIDGET_CONFIG_FOR_APP,
    });

    if (!characterId) {
        return (
            <div className={styles.widgetLoader}>
                <p>Cannot get character id.</p>
            </div>
        );
    }

    return (
        <>
            {widgetConfig ? (
                <ChatWidgetRoom
                    chatWidgetVersion={CHAT_WIDGET_VERSION.MOBILE}
                    chatWidgetConfig={widgetConfig}
                    chatBoxClass={clsx(styles.chatWidgetMobile, chatBoxClass)}
                />
            ) : (
                <div className={clsx(styles.widgetLoader)}>
                    <BouncingDots />
                </div>
            )}
        </>
    );
};
export default Chatroom;
