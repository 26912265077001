import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { MButton } from "../../../components/button";
import { FormUploadFile } from "../../../components/form";
import { PageLoader } from "../../../components/loader";
import { MIME_TYPES } from "../../../constants";
import { useKnowledgeExport, useKnowledgeImport } from "../hooks";
import styles from "./KnowledgeExportImport.module.scss";

const acceptTypes = [MIME_TYPES.xlsx];

const KnowledgeExportImport = () => {
    const { t } = useTranslation();
    const hasKnowledge = true;

    const { projectId } = useParams();
    const { handleExport, exportLoading } = useKnowledgeExport({ project_id: projectId });
    const { methods, importLoading, formSubmit } = useKnowledgeImport();
    const { isDirty, isValid } = methods.formState;
    return (
        <div className={styles.pageBox}>
            <PageLoader loading={exportLoading || importLoading} />
            <div className={styles.contentBox}>
                <div className={styles.typographyWrap}>
                    <div className={styles.typographyTitle}>{t("KNOWLEDGE.Export")}</div>
                    <div className={styles.typography}>{`${t("KNOWLEDGE.Export_Knowledge_types")}:${t(
                        "KNOWLEDGE.Knowledge_inuse"
                    )} (${t("KNOWLEDGE.No_Public")})`}</div>
                    <div className={styles.btnWrap}>
                        <MButton
                            btnText={t("KNOWLEDGE.Export_Btn")}
                            icon={<DownloadRoundedIcon sx={{ fontSize: 20 }} />}
                            type='submit'
                            color='info'
                            sx={{ color: "#1b0063" }}
                            disabled={!hasKnowledge}
                            onClick={handleExport}
                        />
                        <div className={styles.typographySupport}>
                            {hasKnowledge ? "" : <>{t("KNOWLEDGE.Create_Knowledge_First")}</>}
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.contentBox}>
                {hasKnowledge && (
                    <div className={styles.typographyWrap}>
                        <div className={styles.typographyTitle}>{t("KNOWLEDGE.Import")}</div>
                        <div className={styles.typography}>{t("KNOWLEDGE.Import_Edited")}</div>

                        <FormProvider {...methods}>
                            <form onSubmit={formSubmit} className={styles.form}>
                                <div className={styles.uploadFileContainer}>
                                    <FormUploadFile
                                        limit={1}
                                        name='textFile'
                                        acceptTypes={acceptTypes}
                                        maxLimit={5 * 1024 * 1024}
                                    >
                                        <p className={styles.uploadExplanation}>
                                            {t("KNOWLEDGE.Import_Excel_Max_Size")}
                                        </p>
                                    </FormUploadFile>
                                </div>
                                <MButton
                                    btnText={t("KNOWLEDGE.Import_Btn")}
                                    icon={<UploadRoundedIcon sx={{ fontSize: 20 }} />}
                                    type='submit'
                                    color='info'
                                    sx={{ color: "#1b0063", mt: "1rem" }}
                                    disabled={!isDirty || !isValid}
                                />
                            </form>
                        </FormProvider>
                    </div>
                )}
            </div>
        </div>
    );
};

export default KnowledgeExportImport;
